import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import { ContainerSVGT, MainFont, HomeColor, GivingColor, SixthPage } from '../components/Sampler'

export const GivingPageTemplate = ({
  backgroundimage,
}) => {

  const [more, setMore] = useState(false)
  
  useEffect(() => {
    const newElement = document.createElement('script')
    newElement.src = 'https://tithe.ly/widget/v3/give.js?3'
    document.body.appendChild(newElement)
    
    setTimeout(() => {
      const secondElement = document.createElement('script')
      secondElement.type = 'text/javascript'
      secondElement.async = 'true'
      secondElement.innerHTML = `var tw = create_tithely_widget()`
      document.body.appendChild(secondElement) 
    }, 200)
  })

  return (
    <div>
      <div
        className="full-width-image margin-top-0"
        style={{
          backgroundImage: `url(${!!backgroundimage.childImageSharp ? backgroundimage.childImageSharp.fluid.src : backgroundimage
            })`,
        }}
      >

        {/* Font Imports */}
        <link
          href="https://fonts.googleapis.com/css2?family=IM+Fell+English&display=swap"
          rel="stylesheet"
        ></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Crimson+Text&family=Poppins:wght@300&display=swap"
          rel="stylesheet"
        ></link>
        <link href="https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;700;900&display=swap" rel="stylesheet"></link>
        <link href="https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap" rel="stylesheet"></link>
        <link href="https://fonts.googleapis.com/css2?family=Dosis:wght@500&display=swap" rel="stylesheet"></link>
        <link href="https://fonts.googleapis.com/css2?family=Playfair+Display+SC:wght@400&display=swap" rel="stylesheet"></link>
        <link href="https://fonts.cdnfonts.com/css/bahnschrift" rel="stylesheet"></link>

        {/* GIVING IMAGE HEADING */}
        <div style={{ display: 'grid' }}>

          {/* Colored Style Container */}
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            height="310px" viewBox="0 0 1563.000000 1525.000000"
            preserveAspectRatio="xMidYMid meet"
            style={{
              gridRow: '1 / 1',
              gridColumn: '1 / 1',
            }}>

            <g transform="translate(0.000000,-25.000000) scale(0.100000,0.100000)"
              fill={GivingColor} stroke="none">
              <path d={ContainerSVGT} />
            </g>
          </svg>

          {/* "Giving" Text */}
          <div style={{
            gridRow: '1 / 1',
            gridColumn: '1 / 1',
            alignSelf: 'center',
            textAlign: 'center',
            fontSize: '3.2em',
            color: '#FFFFFF',
            fontFamily: MainFont,
            fontWeight: MainFont === 'IM Fell English' || MainFont === 'Permanent Marker' ? null : 'bold',
            letterSpacing: MainFont === 'Dosis' ? '0.14em' : MainFont === 'Playfair Display SC' || MainFont === 'Bahnschrift' ? '0.05em' : null
          }}>
            GIVING
          </div>
        </div>
      </div>
      <section className="section">
        <div className="container"> 
            
          <div>
            <button className="tithely-give-btn" style={{
              backgroundColor: GivingColor,
              fontFamily: MainFont
            }} data-church-id="8327350">
              Give Online
            </button>
            {/* <script id="firstTithely" src="https://tithe.ly/widget/v3/give.js?3"></script>
            <script async='true' >
              var tw = create_tithely_widget()
            </script> */}
          </div>

          {more === false ? 

            <button className="give-button" style={{
              backgroundColor: GivingColor,
              fontFamily: MainFont
            }} onClick={() => setMore(true)}>
              Send a Check
            </button>
          :
          <div>
            <button className="give-button" style={{
              backgroundColor: GivingColor,
              fontFamily: MainFont
            }} onClick={() => setMore(false)}>
              Send a Check
            </button>
              <p className="check-address">Thank you for considering giving to Atlantic Baptist Church! <br></br><br></br>You can address your checks to <b>Atlantic Baptist Church</b> and your envelopes to <br></br><br></br><i>10084 Atlantic Road<br></br>P.O. Box 397<br></br>Atlantic, VA 23303</i></p>
          </div>
          }
        </div>
      </section>
    </div>
  )
}

GivingPageTemplate.propTypes = {
  backgroundimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

const GivingPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <GivingPageTemplate
        backgroundimage={frontmatter.backgroundimage}
      />
    </Layout>
  )
}

GivingPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}


export default GivingPage

export const pageQuery = graphql`
  query GivingPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "giving-page" } }) {
      frontmatter {
        backgroundimage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`